<template>
  <div>
    <v-simple-table class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center"></th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectDeliveryCompany
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="id_delivery_company"
                sort-name="delivery_company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <DateRangeFilter
                :label="$t('labels.created_at')"
                :placeholder="$t('labels.created_at')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectEmployee
                :label="$t('labels.employee')"
                :placeholder="$t('labels.employee')"
                name="id_employee"
                sort-name="employee_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectFilter
                :options="sourceOptions"
                :label="$t('labels.source')"
                :placeholder="$t('labels.source')"
                name="is_order_cancel"
                sort-name="is_order_cancel"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectFilter
                :options="imageOptions"
                :label="$t('labels.report_image')"
                :placeholder="$t('labels.report_image')"
                name="has_image"
                sort-name="has_image"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <InputFilterFromTo
                :label="$t('labels.order')"
                :placeholder="$t('labels.order')"
                name="count_order"
                sort-name="count_order"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <InputFilter
                :label="$t('labels.tracking')"
                :placeholder="$t('labels.tracking')"
                name="order_tracking_id"
                @onFilter="onFilterChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>
              <UploadReturnReceiveFile
                v-if="checkRoleUpDownload(item)"
                :item="item"
                @refreshData="getList"
              />
            </td>
            <td>{{ item.delivery_company_name }}</td>
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>{{ item.employee_name }}</td>
            <td>
              {{
                item.is_order_cancel
                  ? $t("labels.canceled_order")
                  : $t("labels.handed_over_order")
              }}
            </td>
            <td><ReturnReceiveImage :item="item" /></td>
            <td>{{ item.count_order }}</td>
            <td>
              <v-btn
                v-if="checkRoleUpDownload(item)"
                small
                color="success"
                outlined
                @click="downloadHandoverFile(item)"
                ><img
                  src="@/assets/common/download.png"
                  style="height: 24px"
                  alt=""
              /></v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  debounce,
  formatDateTime,
  downloadPdfFile,
  downloadExcelFile,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS, ORDER_RETURN_SOURCES } from "@/libs/const";

export default {
  components: {
    SelectDeliveryCompany: () =>
      import("@/components/table/SelectDeliveryCompany"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    UploadReturnReceiveFile: () =>
      import("@/components/goods_return/UploadReturnReceiveFile"),
    ReturnReceiveImage: () =>
      import("@/components/goods_return/ReturnReceiveImage"),
  },
  name: "ReturnReceiveHistory",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    sort: {},
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS],
    sourceOptions: [...ORDER_RETURN_SOURCES],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    formatDateTime,
    downloadPdfFile,
    downloadExcelFile,
    checkRoleUpDownload(item) {
      if (!window.me || !window.me.employee || !window.me.employee.id) {
        return false;
      }
      if (this.checkPermission(["download_return_file"])) {
        return true;
      }
      const employeeId = window.me.employee.id;
      return employeeId === item.id_employee;
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-return/v1/list-receive", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    downloadHandoverFile(item) {
      const filename = `nhan-hoan-${item.tracking_id}.xlsx`;
      this.downloadExcelFile(
        `/download/v1/return-session-file`,
        { id: item.id },
        filename
      );
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
